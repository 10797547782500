import Base = require("Everlaw/Base");
import { Element as ContextElement } from "Everlaw/Context/UI/Element";
import ContextSelect = require("Everlaw/Context/UI/BaseSelect");
import SingleSelect = require("Everlaw/UI/SingleSelect");

const ContextSingleSelect = new (class<T extends Base.Object> extends ContextElement<
    typeof SingleSelect,
    ContextSingleSelect.Params<T>
> {
    getBase() {
        return SingleSelect;
    }
    getContextParams(params: ContextSingleSelect.Params<T>) {
        return {
            updateArgs: (args) => [ContextSelect.updateBaseSelectParams(args[0], params)],
        };
    }
})();

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ContextSingleSelect {
    export interface Params<T extends Base.Object> extends ContextSelect.Params<T, T> {}
}

export = ContextSingleSelect;
