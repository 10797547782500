import dojo_on = require("dojo/on");

import { PopoverPlacement } from "design-system";
import { EverId, setEverId } from "Everlaw/EverAttribute/EverId";
import Arr = require("Everlaw/Core/Arr");
import Is = require("Everlaw/Core/Is");
import Obj = require("Everlaw/Core/Obj");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import UI = require("Everlaw/UI");
import FloatingPanel = require("Everlaw/UI/FloatingPanel");
import Icon = require("Everlaw/UI/Icon");
import TextBox = require("Everlaw/UI/TextBox");
import Tooltip = require("Everlaw/UI/Tooltip");
import UrlHash = require("Everlaw/UrlHash");
import Util = require("Everlaw/Util");
import RecommendationStep, { StepDisplayer } from "Everlaw/SmartOnboarding/RecommendationStep";
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

/// SideBar functionality

/**
 * Parameters common to the construction of both SB
 */
export interface SBNodeParams {
    id: string;
    display: string;
    hideHeader?: boolean;
    icon?: string;
    headerDisplay?: string | HTMLElement;
    iconRight?: string;
    // TODO: the hidden parameter doesn't interact well with SideBarParams.hasFilter because
    // filtering hides and later unhides nodes, which prevents this parameter from permanently
    // hiding a tab. If we need to combine those behaviors we'll need to track this parameter
    // more explicitly.
    hidden?: boolean;
    allowMoveTo?: (nextNode: SBNode, moveToNextNode: () => void) => boolean;
    onSelect?: (isInitial: boolean) => void;
    onInitialSelect?: () => void;
    onDeselect?: () => void;
    selectable?: boolean;
    light?: boolean;
    /**
     * Whether the node can selected even if it is the currently selected node. Defaults to false.
     */
    selectOnSame?: boolean;
    tooltipStep?: RecommendationStep;
    tooltipStepDisplayer?: StepDisplayer<unknown>;
    tooltipEverId?: EverId;
}

export interface SBParentParams extends SBNodeParams {
    childParams?: SBNodeParams[];
    onInitialChildSelect?: () => void;
    initOnChildInit?: boolean;
    inFooter?: boolean;
}

export abstract class SBNode {
    id: string;
    element: HTMLElement;
    // Following may be null
    content: HTMLElement;
    header: HTMLElement;
    next: SBNode;
    prev: SBNode;
    display: string;
    destroyables: Util.Destroyable[] = [];
    wasSelected: boolean = false;
    focusDiv: FocusDiv;
    private icon: Icon;
    private elementSpan: HTMLElement;
    private light = false;
    tooltipStep?: RecommendationStep;
    tooltipEverId?: EverId;
    selectable: boolean;
    selectOnSame: boolean;
    constructor(params: SBNodeParams, node?: HTMLElement) {
        Object.assign(this, params);
        this.element = Dom.create("div", node);
        if (params.icon) {
            this.icon = new Icon(params.icon, { parent: this.element, alt: "" });
        }
        if (params.tooltipStep) {
            if (params.tooltipEverId) {
                setEverId(this.element, this.tooltipEverId);
            }
            params.tooltipStepDisplayer = {
                ...params.tooltipStepDisplayer,
                node: this.tooltipEverId,
                placement: [PopoverPlacement.RIGHT],
            };
            params.tooltipEverId = this.tooltipEverId;
            params.tooltipStep.registerDisplayer(params.tooltipStepDisplayer);
        }
        Dom.setContent(
            (this.elementSpan = Dom.place(Dom.span({ class: "element-span" }), this.element)),
            this.display,
        );
        if (params.iconRight) {
            this.icon = new Icon(`${params.iconRight} icon-right`, {
                parent: this.element,
                alt: "",
            });
        }
        params.hidden && Dom.hide(this.element, params.hidden);
        this.content = document.getElementById(params.id);
        if (this.content) {
            this.header = Dom.create(
                "h1",
                {
                    class: "sidebar-content-header",
                    content: params.headerDisplay || params.display,
                    id: params.id + "-header",
                },
                this.content,
                "first",
            );
            Dom.show(this.header, !params.hideHeader);
        }
        this.destroyables.push(new Tooltip.MirrorTooltip(this.elementSpan, undefined, ["after"]));
        this.focusDiv = makeFocusable(this.element, "focus-no-space-style", "first");
        this.destroyables.push(this.focusDiv);
        Dom.removeAttr(this.element, "tabindex");
        this.tooltipStep = params.tooltipStep;
    }

    select() {
        Dom.addClass(this.element, "selected");
        Dom.setAttr(this.element, "aria-current", "location");
        if (this.content) {
            Util.setSkipButtons(null, this.content.id, false);
            Dom.show(this.content);
        }
        if (this.light && this.icon) {
            this.icon.setWhite();
        }
        if (!this.wasSelected) {
            this.onInitialSelect();
        }
        this.onSelect(!this.wasSelected);
        this.wasSelected = true;
        if (!this.selectable) {
            Dom.byId(this.id).scrollTop = 0;
        }
    }
    deselect(toSelect: string) {
        this.onDeselect();
        Dom.removeClass(this.element, "selected");
        Dom.removeAttr(this.element, "aria-current");
        this.content && Dom.hide(this.content);
        if (this.light && this.icon) {
            this.icon.setWhite(false);
        }
    }
    /**
     * If this node is selected and the user clicks nextNode should we allow them to go to it
     * or force them to remain here?
     *
     * If you want to prompt the user to finish something before moving away from this node
     * you can return false and trigger the move programmatically when you're ready.
     *
     * @param nextNode the target node to move to
     * @param moveToNextNode a function that will send you to the newly selected node
     * @return true to move to nextNode immediately, false to remain on the current node for now
     */
    allowMoveTo(nextNode: SBNode, moveToNextNode: () => void) {
        return true;
    }
    onSelect(isInitial: boolean) {}
    onDeselect() {}
    onInitialSelect() {}
    destroy() {
        Dom.destroy(this.element);
        Dom.destroy(this.content);
        Util.destroy(this.destroyables);
    }
    setDisplay(newDisplay: string) {
        this.display = newDisplay;
        Dom.setContent(this.elementSpan, this.display);
    }
    setHeader(newHeader: string) {
        Dom.setContent(this.header, newHeader);
    }
    showIcon(visible?: boolean) {
        if (!this.icon) {
            return;
        }
        Dom.show(this.icon, visible);
    }
}

export class SBChild extends SBNode {
    constructor(
        public parent: SBParent,
        params: SBNodeParams,
    ) {
        super(params);
        Dom.addClass(this.element, "sidebar-child");
        Dom.toggleClass(this.element, "light", !!params.light);
    }

    override deselect(toSelect: string) {
        super.deselect(toSelect);
        if (!this.parent.children[toSelect]) {
            // Collapse parent if selecting a non-sibling
            this.parent.toggle();
            Dom.removeClass(this.parent.element, "child-selected");
        }
    }

    override select() {
        this.parent.onChildSelect();
        super.select();
        Dom.addClass(this.parent.element, "child-selected");
    }
}

export class SBParent extends SBNode {
    children: { [id: string]: SBChild } = {};
    /** Lazily initialized once the parent has children. */
    childrenDiv: HTMLElement;
    /** whether to call onInitialSelect when a child is selected */
    private initOnChildInit: boolean;
    /** Total height of non-hidden children nodes. */
    private childrenHeight: number;
    private isExpanded: boolean = false;
    private childWasSelected = false;
    /** The height of a single child node. */
    private static childHeight = 40;
    private childrenOrder: string[] = [];
    constructor(
        params: SBParentParams,
        private section: HTMLElement,
    ) {
        super(params, section);
        Dom.addClass(this.element, "sidebar-parent");
        Dom.toggleClass(this.element, "light", !!params.light);
        if (params.childParams) {
            let prevChild: SBChild;
            params.childParams.forEach((cp) => {
                const child = this.addChild(Object.assign(cp, { light: params.light }));
                // TODO: wrong if external elements call addChild
                child.prev = prevChild;
                if (prevChild) {
                    prevChild.next = child;
                }
                prevChild = child;
            });
        }
    }

    addChild(params: SBNodeParams, pos?: number) {
        if (!this.childrenDiv) {
            const classes = "sidebar-children" + (params.light ? " light" : "");
            this.childrenDiv = Dom.create("div", { class: classes }, this.section);
            this.childrenHeight = 0;
        }
        const child = new SBChild(this, params);
        this.children[child.id] = child;
        if (!Dom.isHidden(child.element)) {
            this.childrenHeight += SBParent.childHeight;
        }
        if (this.isExpanded && !Dom.isHidden(child.element)) {
            Dom.style(child.element, "height", "0");
            Dom.place(child.element, this.childrenDiv, pos);
            gsap.to(child.element, {
                duration: 0.25,
                height: SBParent.childHeight + "px",
            });
            gsap.to(this.childrenDiv, {
                duration: 0.25,
                height: this.childrenHeight + "px",
            });
        } else {
            Dom.place(child.element, this.childrenDiv, pos);
        }
        Dom.setAttr(child.focusDiv.node, "tabIndex", "-1");
        child.destroyables.push(
            Input.fireCallbackOnKey(
                child.focusDiv.node,
                [Input.ARROW_UP, Input.ARROW_DOWN],
                (e) => {
                    let ind = this.childrenOrder.indexOf(child.id) + this.childrenOrder.length;
                    ind = (ind + (e.key === Input.ARROW_DOWN ? 1 : -1)) % this.childrenOrder.length;
                    const nextId = this.childrenOrder[ind];
                    const nextItem = this.children[nextId];
                    nextItem.focusDiv.focus();
                },
            ),
        );
        this.childrenOrder.push(child.id);
        return child;
    }
    recalculateChildrenHeight() {
        if (!this.hasChildren()) {
            return;
        }
        let nVisibleChildren = 0;
        // Resize parent to the combined height of all visible children.
        Object.values(this.children).forEach((node: SBNode) => {
            if (!Dom.isHidden(node.element)) {
                nVisibleChildren += 1;
            }
        });
        this.childrenHeight = nVisibleChildren * SBParent.childHeight;
        if (this.isExpanded) {
            gsap.to(this.childrenDiv, {
                duration: 0.25,
                height: this.childrenHeight + "px",
            });
        }
    }
    toggle() {
        if (!this.hasChildren()) {
            return;
        }
        if (!this.isExpanded) {
            gsap.to(this.childrenDiv, {
                duration: 0.25,
                height: this.childrenHeight + "px",
            });
        } else {
            gsap.to(this.childrenDiv, {
                duration: 0.25,
                height: "0",
            });
        }
        const tIndex = this.isExpanded ? "-1" : "0";
        for (const key in this.children) {
            const childValue = this.children[key];
            Dom.setAttr(childValue.focusDiv.node, "tabIndex", tIndex);
        }
        this.isExpanded = !this.isExpanded;
        return this.isExpanded;
    }
    isParentOf(child: SBChild) {
        return !!this.children[child.id];
    }
    override select() {
        super.select();
        this.expand();
    }
    expand() {
        if (!this.isExpanded) {
            this.toggle();
        }
    }
    hasBeenExpanded() {
        return this.isExpanded;
    }
    collapse() {
        if (this.isExpanded) {
            this.toggle();
        }
    }
    override deselect(toSelect: string) {
        super.deselect(toSelect);
        // Don't collapse if we're selecting a child
        if (this.hasChildren() && !this.children[toSelect]) {
            this.toggle();
        }
    }
    onChildSelect() {
        if (!this.isExpanded) {
            this.toggle();
        }
        if (!this.childWasSelected) {
            if (this.initOnChildInit && !this.wasSelected) {
                this.onInitialSelect();
                this.wasSelected = true;
            }
            this.onInitialChildSelect();
            this.childWasSelected = true;
        }
    }
    onInitialChildSelect() {}
    override destroy() {
        Object.values(this.children).forEach((child: SBChild) => {
            child.destroy();
        });
        Dom.destroy(this.childrenDiv);
        super.destroy();
    }
    hasChildren() {
        return !Obj.empty(this.children);
    }
    numChildren() {
        return Obj.size(this.children);
    }
}

export type SideBarNodes = { [id: string]: SBNode };

export interface SideBarParams extends UI.WidgetWithTextBoxParams {
    parents: SBParentParams[];
    // parents should not have anything in common with hiddenParents
    hiddenParents?: SBParentParams[];
    initial: string;
    /**
     * Whether we should remove the URL hash associated with {@link #initial} from the URL. If not
     * defined, we'll remove the hash.
     */
    removeInitialFromHash?: boolean;
    onShowTab?: (tabId: string, oldTabId: string) => void;
    changeHash?: boolean;
    hashState?: string;
    hasFilter?: boolean;
    // Only does anything if hasFilter is truthy. Should return true if the element should be
    // considered as part of the filter, and false otherwise.
    shouldFilter?: (tabId: string) => boolean;
    filterPrompt?: string;
    silentInitialSelect?: boolean;
    footer?: Dom.Content;
    light?: boolean;
    topElement?: HTMLElement;
    allowStickyOptions?: boolean;
    nonStickySidebarHeight?: string;
}

export const TAB_HASH = "tab";

export class SideBar implements UI.WidgetWithTextBox {
    node: HTMLElement;
    tabsSection: HTMLElement;
    initial: string;
    current: SBNode;
    previous: SBNode;
    expanded: SBParent;
    sbNodes: SideBarNodes = {};
    parentNodes: SBParent[] = [];
    stickyOptionsNode: HTMLElement;
    protected hashState = TAB_HASH;
    private hashCallback: Util.Destroyable;
    private changeHash: boolean;
    private filterBox: TextBox;
    constructor(
        node: Dom.Nodeable,
        public params: SideBarParams,
    ) {
        this.node = Dom.node(node);
        if (params.allowStickyOptions) {
            this.tabsSection = Dom.div();
            this.stickyOptionsNode = Dom.div({ class: "sidebar-footer" }, params.footer ?? []);
            Dom.place([this.tabsSection, this.stickyOptionsNode], this.node);
            if (params.nonStickySidebarHeight) {
                Dom.style(this.tabsSection, { height: params.nonStickySidebarHeight });
                Dom.addClass(this.tabsSection, "non-sticky-sidebar");
            }
        } else {
            this.tabsSection = this.node;
        }
        Dom.setAttr(this.node, "role", "navigation");
        Util.setSkipButtons(this.node.id, null, false);
        if (this.params.hasFilter) {
            if (this.params.shouldFilter) {
                this.shouldFilter = this.params.shouldFilter;
            }
            const filterSection = Dom.create("div", { class: "sidebar-section" }, this.tabsSection);
            this.filterBox = new TextBox({
                placeholder: this.params.filterPrompt || "Filter",
                textBoxAriaLabel: params.textBoxAriaLabel,
                textBoxLabelContent: params.textBoxLabelContent,
                textBoxLabelPosition: params.textBoxLabelPosition,
                clearMark: true,
                inputClass: "sidebar-filter-box",
            });
            Dom.style(this.filterBox, { margin: "8px 0 8px 8px", width: "calc(100% - 16px)" });
            this.filterBox.onChange = () => {
                this.filter(this.filterBox.getValue());
            };
            Dom.style(filterSection, { paddingTop: "10px" });
            Dom.place(this.filterBox, filterSection);
        }
        params.parents.forEach((pp) =>
            this.addParent(Object.assign(pp, { light: !!params.light }), null),
        );
        if (params.hiddenParents) {
            params.hiddenParents.forEach((pp) =>
                this.addParent(Object.assign(pp, { light: !!params.light }), null, true),
            );
        }
        // the topElement should be above the filter, if both exist
        if (this.params.topElement) {
            const topSection = Dom.create(
                "div",
                { class: "sidebar-section" },
                this.tabsSection,
                "first",
            );
            Dom.place(this.params.topElement, topSection, "first");
        }
        if (this.params.footer) {
            Dom.place(Dom.div({ class: "sidebar-footer" }, this.params.footer), this.node);
        }
        this.initial = this.params.initial;
        if (Is.defined(params.hashState)) {
            this.hashState = params.hashState;
        }
        this.changeHash = Is.defined(params.changeHash) ? params.changeHash : true;
        if (this.changeHash) {
            this.hashCallback = UrlHash.subscribe((hash) => {
                this.hashChange(hash);
            });
            if (this.hashChange(UrlHash.get())) {
                // initial tab set from hash
                return;
            } // else no tab in hash; just fall through to normal initial tab selection
        } else {
            this.select(this.initial, !!params.silentInitialSelect);
        }
        Dom.toggleClass(this.node, "light", !!params.light);
    }
    /**
     * Sets the tab based on the value in the hash, if it differs from the currentTab or the
     * currentTab is not set yet. If there is no tab value in the hash, returns false.
     */
    hashChange(hash: UrlHash.Hash) {
        if (!this.changeHash) {
            return false;
        }
        const nodeId = this.getNodeId(hash);
        if (!this.sbNodes[nodeId]) {
            return false;
        }
        if (this.current && this.current.id === nodeId) {
            // the tab in the hash already matches the one that is selected (called as a result of
            // the update in select?); no more work to do
            return true;
        }
        this.select(nodeId);
        return true;
    }
    protected getNodeId(hash: UrlHash.Hash) {
        return hash[this.hashState] || this.initial;
    }
    private _whenSelected(n: SBNode) {
        if (
            !this.current
            || this.current.allowMoveTo(n, () => {
                this.select(n.id);
            })
        ) {
            this.select(n.id);
        }
    }
    connect(n: SBNode) {
        this.sbNodes[n.id] = n;
        n.destroyables.push(
            dojo_on(n.element, Input.press, () => {
                this._whenSelected(n);
            }),
        );
        n.destroyables.push(
            Input.fireCallbackOnKey(n.focusDiv.node, [Input.ENTER, Input.SPACE], (evt) => {
                evt.stopPropagation();
                this._whenSelected(n);
            }),
        );
    }

    select(nodeId: string, silent = false): void {
        const which = this.sbNodes[nodeId];
        if (which === this.current && !which.selectOnSame) {
            return;
        }
        if (this.expanded && this.expanded !== which) {
            if (!this.expanded.children[nodeId]) {
                this.expanded.toggle();
            }
            this.expanded = null;
        }
        if (which instanceof SBParent && !which.content && !which.selectable) {
            // Can't be selected. Just toggle the children
            if (this.current && which.children[this.current.id]) {
                return;
            }
            this.expanded = which.toggle() ? which : null;
            this.expanded && ga_event("Expand Tab", this.expanded.display);
            return;
        }
        // The selected node definitely has a sidebar-content div to display. Select it.
        FloatingPanel.closeAll();
        ga_event("Show Tab", which.display);
        let prevId: string;
        if (this.current) {
            if (which !== this.current) {
                this.current.deselect(which.id);
            }
            prevId = this.current.id;
        }
        which.select();
        this.previous = this.current;
        this.current = which;
        if (this.params.onShowTab && !silent) {
            this.params.onShowTab(which.id, prevId);
        }

        if (this.changeHash) {
            const removeInitialFromHash = Is.defined(this.params.removeInitialFromHash)
                ? this.params.removeInitialFromHash
                : true;
            // this must come after we set currentTab to avoid an infinite callback loop
            // this will trigger hashChange, but currentTab will match and it will exit
            if (removeInitialFromHash && this.current.id === this.initial) {
                UrlHash.remove(this.hashState);
            } else {
                UrlHash.add({ [this.hashState]: this.current.id });
            }
        }
    }
    selectPrevious(silent = false) {
        if (this.previous && this.previous.id in this.sbNodes) {
            this.select(this.previous.id, silent);
            return true;
        }
        return false;
    }
    getContent(nodeId: string) {
        return this.sbNodes[nodeId];
    }
    /**
     * Sets the content of the element (left-side div) for the given node.
     */
    setNodeElementContent(idOrNode: SBNode | string, content: string) {
        const node = idOrNode instanceof SBNode ? idOrNode : this.sbNodes[idOrNode];
        node.setDisplay(content);
    }
    /**
     * Sets the content of the header for the given node.
     */
    setNodeHeader(idOrNode: SBNode | string, content: string) {
        const node = idOrNode instanceof SBNode ? idOrNode : this.sbNodes[idOrNode];
        node.setHeader(content);
    }
    /**
     * Register a new tab with the given content and id.
     */
    addParent(params: SBParentParams, pos?: number, hiddenSection?: boolean): SBParent {
        const classes = hiddenSection ? "sidebar-section hidden" : "sidebar-section";
        const section = Dom.create(
            "div",
            { class: classes },
            params.inFooter ? this.stickyOptionsNode : this.tabsSection,
            pos,
        );
        const parent = new SBParent(params, section);
        this.connect(parent);
        if (!Is.defined(pos)) {
            pos = this.parentNodes.length;
            this.parentNodes.push(parent);
        } else {
            this.parentNodes.splice(pos, 0, parent);
        }
        const p = pos > 0 ? this.parentNodes[pos - 1] : null;
        const n = pos < this.parentNodes.length - 1 ? this.parentNodes[pos + 1] : null;
        if (p) {
            p.next = parent;
            parent.prev = p;
        }
        if (n) {
            n.prev = parent;
            parent.next = n;
        }
        Object.values(parent.children).forEach((child: SBChild) => {
            this.connect(child);
            if (child.tooltipStep && !child.tooltipStep.displayer) {
                setEverId(child.element, child.tooltipEverId);
                child.tooltipStep.registerDisplayer({
                    node: child.tooltipEverId,
                    placement: [PopoverPlacement.RIGHT],
                    nextFunction: () => {
                        this.select(child.id);
                    },
                });
            }
        });
        return parent;
    }
    addChild(params: SBNodeParams, parentIdOrNode: SBParent | string, pos?: number) {
        const parent =
            parentIdOrNode instanceof SBParent
                ? parentIdOrNode
                : <SBParent>this.sbNodes[parentIdOrNode];
        const child = parent.addChild(params, pos);
        this.connect(child);
    }
    recalculateChildrenHeight(id: string) {
        const node = this.sbNodes[id];
        let parent: SBParent;
        if (node && node instanceof SBChild) {
            parent = node.parent;
        } else if (node && node instanceof SBParent) {
            parent = node;
        }
        if (parent) {
            parent.recalculateChildrenHeight();
        }
    }
    removeNode(idOrNode: SBNode | string, destroyPane = true) {
        const which = idOrNode instanceof SBNode ? idOrNode : this.sbNodes[idOrNode];
        const id = which.id;
        if (this.current === which) {
            if (which.next) {
                this.select(which.next.id);
            } else {
                this.current = null;
            }
        }
        if (this.previous === which) {
            this.previous = null;
        }
        if (destroyPane) {
            which.destroy();
        } else {
            Dom.destroy(which.element);
        }
        delete this.sbNodes[id];
        if (which instanceof SBChild) {
            delete which.parent.children[id];
            which.parent.recalculateChildrenHeight();
        }
        if (which instanceof SBParent) {
            Object.values(which.children).forEach((node) => {
                this.removeNode(node, destroyPane);
            });
            const idx = Arr.first(this.parentNodes, (n) => n === which);
            if (idx >= 0) {
                const p = idx > 0 ? this.parentNodes[idx - 1] : null;
                const n = idx < this.parentNodes.length - 1 ? this.parentNodes[idx + 1] : null;
                if (p) {
                    p.next = n;
                }
                if (n) {
                    n.prev = p;
                }
                this.parentNodes.splice(idx, 1);
            }
        }
    }
    removeNodeAndParent(id: string, destroyPane = true) {
        const which = this.sbNodes[id];
        this.removeNode(which instanceof SBChild ? which.parent : which, destroyPane);
    }
    destroy() {
        Util.destroy(this.hashCallback);
        Object.values(this.sbNodes).forEach((node: SBNode) => {
            node.destroy();
        });
    }
    /**
     * Override (using constructor param or directly) to exclude certain tabs from filtering.
     * In particular, returning false causes a tab to only be shown (in the face of filtering) if:
     *     1. it's a child and its parent matches, or
     *     2. it's a parent and one of its children matches
     */
    shouldFilter(tabId: string) {
        return true;
    }
    filter(content: string) {
        if (!content) {
            // Unhide all nodes (nothing is filtered out any more),
            // but only expand the current parent (i.e., return to the default view).
            const currentParent =
                this.current instanceof SBParent ? this.current : (<SBChild>this.current).parent;
            this.parentNodes.forEach((node) => {
                Dom.show(node.element);
                highlightifySidebar(node, null);
                node.childrenDiv && Dom.show(node.childrenDiv);
                Object.values(node.children).forEach((child) => {
                    Dom.show(child.element);
                    highlightifySidebar(child, null);
                });
                this.recalculateChildrenHeight(node.id);
                if (node === currentParent) {
                    node.expand();
                } else {
                    node.collapse();
                }
            });
            return;
        }

        // If a parent matches we show it and its children.
        // If a child matches we show it and its parent, but not necessarily its siblings.
        // If shouldFilter is false for a node we never highlightify it and it is shown only
        // under the conditions defined by #shouldFilter.
        content = content.toLocaleLowerCase();
        this.parentNodes.forEach((parent) => {
            let showingChildren = false;
            const shouldFilterParent = this.shouldFilter(parent.id);
            const parentMatches =
                shouldFilterParent && parent.display.toLocaleLowerCase().indexOf(content) >= 0;
            Object.values(parent.children).forEach((child) => {
                // Show if parent matches or child matches.
                const shouldFilterChild = this.shouldFilter(child.id);
                if (
                    parentMatches
                    || (shouldFilterChild
                        && child.display.toLocaleLowerCase().indexOf(content) >= 0)
                ) {
                    Dom.show(child.element);
                    if (shouldFilterChild) {
                        highlightifySidebar(child, content);
                    }
                    showingChildren = true;
                } else {
                    Dom.hide(child.element);
                }
            });
            const showParent = showingChildren || parentMatches;
            Dom.show(parent.element, showParent);
            parent.childrenDiv && Dom.show(parent.childrenDiv, showParent);
            this.recalculateChildrenHeight(parent.id);
            if (showParent) {
                if (shouldFilterParent) {
                    highlightifySidebar(parent, content);
                }
                parent.expand();
            } else {
                // The parent is already hidden, but this sets the internal expansion state.
                parent.collapse();
            }
        });
    }
    focusFilterBox() {
        if (this.filterBox) {
            this.filterBox.focus();
        }
    }
    clearFilter() {
        if (this.filterBox) {
            this.filterBox.clear();
            this.filter(this.filterBox.getValue());
        }
    }
    setTextBoxAriaLabel(ariaLabel: string) {
        this.filterBox.setTextBoxAriaLabel(ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content) {
        this.filterBox.setTextBoxLabelContent(labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        this.filterBox.setTextBoxLabelPosition(position);
    }
    getParentNodeById(id: string): HTMLElement {
        let parentNode: HTMLElement = undefined;
        this.parentNodes.forEach((node: SBParent) => {
            if (node.id === id) {
                parentNode = node.element;
                return;
            }
        });
        return parentNode;
    }
}

function highlightifySidebar(node: SBNode, filter: string) {
    UI.highlightify(
        <HTMLElement>node.element.lastChild,
        node.element.textContent,
        filter,
        "dull-highlight",
    );
}
