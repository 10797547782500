import Arr = require("Everlaw/Core/Arr");
import { Element as ContextElement } from "Everlaw/Context/UI/Element";
import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");
import SideBar = require("Everlaw/UI/SideBar");
import UrlHash = require("Everlaw/UrlHash");

/**
 * A SideBar for when in a non Everlaw context.
 */
const ContextSideBar = new (class extends ContextElement<
    typeof SideBar.SideBar,
    ContextSideBar.Params
> {
    getBase() {
        return SideBar.SideBar;
    }
    getContextParams(params: ContextSideBar.Params) {
        return {
            Base: class extends SideBar.SideBar {
                override select(nodeId: string, silent = false) {
                    if (params.inaccessibleNodes && nodeId in params.inaccessibleNodes) {
                        const inaccessibleNode = params.inaccessibleNodes[nodeId];
                        inaccessibleNode.onClick && inaccessibleNode.onClick();
                        return;
                    }
                    super.select(nodeId, silent);
                }
                override getNodeId(hash: UrlHash.Hash) {
                    if (params.inaccessibleNodes) {
                        const nodeIdFromHash = hash[this.hashState];
                        if (nodeIdFromHash) {
                            return nodeIdFromHash in params.inaccessibleNodes
                                ? this.initial
                                : nodeIdFromHash;
                        }
                    }
                    return super.getNodeId(hash);
                }
            },
            updateArgs: (args: [Dom.Nodeable, SideBar.SideBarParams]) => {
                args[1].parents.push(...(params.additionalParents || []));
                addOmissions(args[1], params);
                params.parentOrder && orderParents(args[1], params.parentOrder);
                params.initial && (args[1].initial = params.initial);
                params.footer && (args[1].footer = params.footer);
                replaceParents(args[1], params);
                return args;
            },
            updateObj: (sb: SideBar.SideBar) => {
                params.inaccessibleNodes
                    && Object.values(sb.sbNodes).forEach((node) => {
                        if (
                            node.id in params.inaccessibleNodes
                            && params.inaccessibleNodes[node.id].updateNode
                        ) {
                            params.inaccessibleNodes[node.id].updateNode(node);
                        }
                    });
            },
        };
    }
})();

function addOmissions(params: SideBar.SideBarParams, contextParams: ContextSideBar.Params) {
    const omissions = Arr.toSet(contextParams.omissions || []);
    // Children of inaccessible parents should also be omitted.
    const inaccessible = Arr.toSet(Object.keys(contextParams.inaccessibleNodes || {}));
    params.parents.forEach((parentParams) => {
        if (inaccessible.has(parentParams.id) && parentParams.childParams) {
            parentParams.childParams.forEach((childParams) => {
                omissions.add(childParams.id);
            });
        }
    });
    params.parents = params.parents
        .filter((parentParams) => !omissions.has(parentParams.id))
        .map((parentParams) => {
            if (parentParams.childParams) {
                parentParams.childParams = parentParams.childParams.filter(
                    (childParams) => !omissions.has(childParams.id),
                );
            }
            return parentParams;
        });
}

function orderParents(params: SideBar.SideBarParams, parentOrder: string[]) {
    const parentIndexMap = Arr.toIndexMap(parentOrder);
    Arr.sort(params.parents, {
        cmp: (parent1, parent2) => {
            return parentIndexMap[parent1.id] - parentIndexMap[parent2.id];
        },
    });
}

function replaceParents(params: SideBar.SideBarParams, contextParams: ContextSideBar.Params) {
    if (!Is.defined(contextParams.replaceParentWithChild)) {
        return;
    }
    params.parents = params.parents.map((parentParams) => {
        for (const childParams of parentParams.childParams || []) {
            if (childParams.id in contextParams.replaceParentWithChild) {
                const replaceParentParams = contextParams.replaceParentWithChild[childParams.id];
                parentParams.id = childParams.id;
                parentParams.display = childParams.display;
                parentParams.hideHeader = childParams.hideHeader;
                parentParams.onInitialChildSelect =
                    replaceParentParams.onInitialSelect || childParams.onInitialSelect;
                parentParams.onDeselect = childParams.onDeselect;
                parentParams.initOnChildInit = false;
                parentParams.childParams = [];
                break;
            }
        }
        return parentParams;
    });
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ContextSideBar {
    export interface Params {
        /** Additional parent params to be added by index. */
        additionalParents?: SideBar.SBParentParams[];
        /** Overrides the SideBarParams#initial attribute. */
        initial?: string;
        /** Overrides the SideBarParams#footer attribute. */
        footer?: Dom.Content;
        /** Nodes that should be omitted entirely from the sidebar. */
        omissions?: string[];
        /** Order of initial and additional parents. Additions are added to the end if omitted. */
        parentOrder?: string[];
        /** A child to use instead of it's parent. No other children will be visible. */
        replaceParentWithChild?: {
            [id: string]: {
                /** A new onInitialSelect method. */
                onInitialSelect?: () => void;
            };
        };
        /** Nodes that should be visible, but inaccessible. The content will not be displayed. */
        inaccessibleNodes?: {
            [id: string]: {
                /** Action to perform when the inaccessible node is clicked. */
                onClick?: () => void;
                /** Update the inaccessible node after the SideBar has been created. */
                updateNode?: (sbNode: SideBar.SBNode) => void;
            };
        };
    }
}

export = ContextSideBar;
